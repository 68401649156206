<template>
  <div ref="RolePermissionSelectionTable">
    <div>
      <b-row class="align-items-center justify-content-between mb-6 mb-sm-0">
        <b-col cols="12" sm="5" md="4" lg="3">
          <b-form class="form" id="kt_form">
            <label>{{ $t('label.name') }}</label>
            <b-form-group class="form-group">
              <b-form-input
                v-model="$v.newRoleName.$model"
                :state="validateNewRoleNameState()"
                type="text"
                class="form-control form-control-solid"
                :disabled="
                  !checkAccessRight(moduleName, [permissionAction.ROLE_UPDATE])
                "
              ></b-form-input>
              <b-form-invalid-feedback>
                {{ $t('fieldErrorMessage.requiredFieldDefault') }}
              </b-form-invalid-feedback>
            </b-form-group>
          </b-form>
        </b-col>
        <b-col cols="auto">
          <b-button
            v-if="
              isNew &&
              checkAccessRight(moduleName, [permissionAction.ROLE_CREATE])
            "
            variant="success"
            class="min-w-120px min-w-md-150px"
            @click="submitForm"
            >{{ $t('label.submit') }}</b-button
          >
          <b-button
            v-if="
              !isNew &&
              checkAccessRight(moduleName, [permissionAction.ROLE_DELETE])
            "
            variant="light-danger"
            class="mr-2 text-capitalize"
            @click="deleteRoleAction"
            >{{ $t('label.deleteRole') }}</b-button
          >
          <b-button
            v-if="
              !isNew &&
              checkAccessRight(moduleName, [permissionAction.ROLE_UPDATE])
            "
            variant="light-primary"
            class="text-capitalize"
            @click="submitForm"
            >{{ $t('label.saveChanges') }}</b-button
          >
        </b-col>
      </b-row>
    </div>

    <div class="overflow-x-auto">
      <div class="permissions-wrapper">
        <b-row no-gutters class="font-weight-bolder p-3">
          <b-col cols="1">
            <b-form-checkbox
              v-model="newPermission.active"
              :indeterminate="newPermission.indeterminate"
              :disabled="
                isNew
                  ? false
                  : !checkAccessRight(moduleName, [
                      permissionAction.ROLE_UPDATE
                    ])
              "
              inline
              size="lg"
              class="mr-0"
              @change="selectAllPermissions(newPermission.active)"
            ></b-form-checkbox>
          </b-col>
          <b-col cols="2">{{ $t('label.module') }}</b-col>
          <b-col cols="2">{{ $t('enumCRUD.create') }}</b-col>
          <b-col cols="2">{{ $t('enumCRUD.read') }}</b-col>
          <b-col cols="2">{{ $t('enumCRUD.update') }}</b-col>
          <b-col cols="2">{{ $t('enumCRUD.delete') }}</b-col>
          <b-col cols="1">
            <span
              class="d-flex justify-content-center align-items-center cursor-pointer expand-icon-span"
              @click="
                isExpandAllChildrenPermissions = !isExpandAllChildrenPermissions
              "
            >
              <i
                :class="`fas fa-angle-double-${
                  !isExpandAllChildrenPermissions ? 'down' : 'up'
                }`"
              ></i>
            </span>
          </b-col>
        </b-row>
        <template v-for="(permission, index) in newPermission.permissions">
          <div :key="index">
            <b-row no-gutters class="border-top p-3">
              <b-col cols="1">
                <b-form-checkbox
                  v-model="permission.active"
                  :indeterminate="permission.indeterminate"
                  :disabled="
                    isNew
                      ? false
                      : !checkAccessRight(moduleName, [
                          permissionAction.ROLE_UPDATE
                        ])
                  "
                  size="lg"
                  @change="changeMasterPermission(permission)"
                ></b-form-checkbox>
              </b-col>
              <b-col cols="2" class="font-weight-bolder">
                {{ $t(`enumModule.${permission.name}`) }}
              </b-col>
              <template v-for="(action, actionIndex) in permission.actions">
                <b-col :key="actionIndex">
                  <b-form-checkbox
                    v-if="isMasterPermissionActionHidden(permission, action)"
                    v-model="action.active"
                    :indeterminate="action.indeterminate"
                    :disabled="disabledAction(permission, action)"
                    size="lg"
                    @change="changeMasterPermissionActions(permission, action)"
                  ></b-form-checkbox>
                </b-col>
              </template>
              <b-col cols="1">
                <span
                  v-if="permission.name != 'flag'"
                  class="d-flex justify-content-center align-items-center cursor-pointer expand-icon-span"
                  @click="
                    permission.childrenVisible = !permission.childrenVisible
                  "
                >
                  <i
                    :class="`fas fa-angle-${
                      !permission.childrenVisible ? 'down' : 'up'
                    }`"
                  ></i>
                </span>
              </b-col>
            </b-row>
            <b-collapse v-model="permission.childrenVisible">
              <template v-for="(children, cIndex) in permission.children">
                <b-row
                  :key="cIndex"
                  no-gutters
                  class="border-top p-3 children-row"
                >
                  <b-col cols="1">
                    <b-form-checkbox
                      v-model="children.active"
                      :indeterminate="children.indeterminate"
                      :disabled="
                        isNew
                          ? false
                          : !checkAccessRight(moduleName, [
                              permissionAction.ROLE_UPDATE
                            ])
                      "
                      size="lg"
                      @change="
                        changeChildrenMasterPermission(permission, children)
                      "
                    ></b-form-checkbox>
                  </b-col>
                  <b-col cols="2" class="font-weight-bolder font-italic">{{
                    $t(`enumFeature.${children.name}`)
                  }}</b-col>
                  <template v-for="(crud, crudIndex) in crudList">
                    <b-col cols="2" :key="crudIndex">
                      <b-form-checkbox
                        v-if="children.actions.find((x) => x.name == crud)"
                        v-model="
                          children.actions.find((x) => x.name == crud).active
                        "
                        :disabled="
                          disabledChildrenAction(
                            children,
                            children.actions.find((x) => x.name == crud)
                          )
                        "
                        size="lg"
                        @change="
                          changeChildrenPermissionActions(
                            permission,
                            children,
                            children.actions.find((x) => x.name == crud)
                          )
                        "
                      >
                      </b-form-checkbox>
                    </b-col>
                  </template>

                  <b-col cols="1"></b-col>
                </b-row>
              </template>
            </b-collapse>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
  import { validationMixin } from 'vuelidate';
  import { required } from 'vuelidate/lib/validators';
  import { CRUDType } from '@/core/constants/enums';
  import { MODULE, ACTION } from '@/core/constants';
  import { permissionHelper } from '@/core/utils';
  import commonMixin from '@/core/mixins/common.mixin';

  export default {
    name: 'RolePermissionSelectionTableNew',
    mixins: [validationMixin, commonMixin],
    props: {
      isNew: {
        type: Boolean,
        default: false
      },
      inject: {
        type: Boolean,
        default: false
      },
      existingRoleName: {
        type: String,
        default: ''
      },
      existingPermission: {
        type: Object,
        default: () => {}
      }
    },
    data: () => ({
      moduleName: MODULE.ROLE,
      permissionAction: ACTION,
      newRoleName: '',
      newPermission: permissionHelper.permissionsSortedByModuleFeatureObject(),
      isExpandAllChildrenPermissions: false,
      crudList: [
        CRUDType.CREATE,
        CRUDType.READ,
        CRUDType.UPDATE,
        CRUDType.DELETE
      ]
    }),
    watch: {
      inject() {
        if (this.inject) this.injectPermission();
      },
      isExpandAllChildrenPermissions() {
        this.expandAllChildren(this.isExpandAllChildrenPermissions);
      }
    },
    methods: {
      isMasterPermissionActionHidden(permission, action) {
        let isHidden = false;

        permission.children.forEach((x) => {
          x.actions.forEach((y) => {
            if (y.name == action.name) {
              isHidden = true;
            }
          });
        });

        return isHidden;
      },
      expandAllChildren(isExpand) {
        this.newPermission.permissions.forEach((x) => {
          x.childrenVisible = isExpand;
        });
      },
      disabledAction(parent, action) {
        let disabled = false;
        let hasActionCreateUpdateDelete = false;

        parent.children.forEach((x) => {
          x.actions.forEach((y) => {
            if (
              [CRUDType.CREATE, CRUDType.UPDATE, CRUDType.DELETE].includes(
                y.name
              )
            ) {
              hasActionCreateUpdateDelete = true;
            }
          });
        });

        if (action.name == CRUDType.READ) {
          parent.actions.forEach((x) => {
            if (
              [CRUDType.CREATE, CRUDType.UPDATE, CRUDType.DELETE].includes(
                x.name
              ) &&
              (x.active || x.indeterminate)
            ) {
              disabled = true;
            }

            if (!hasActionCreateUpdateDelete) {
              disabled = false;
            }
          });
        } else {
          disabled = false;
        }

        if (
          !this.isNew &&
          !this.checkAccessRight(this.moduleName, [
            this.permissionAction.ROLE_UPDATE
          ])
        ) {
          disabled = true;
        }

        return disabled;
      },
      disabledChildrenAction(children, action) {
        let disabled = false;

        if (action.name == CRUDType.READ) {
          children.actions.forEach((x) => {
            if (
              [CRUDType.CREATE, CRUDType.UPDATE, CRUDType.DELETE].includes(
                x.name
              ) &&
              x.active
            ) {
              disabled = true;
            }
          });
        } else {
          disabled = false;
        }

        if (
          !this.isNew &&
          !this.checkAccessRight(this.moduleName, [
            this.permissionAction.ROLE_UPDATE
          ])
        ) {
          disabled = true;
        }

        return disabled;
      },
      selectAllPermissions(active) {
        this.newPermission.permissions.forEach((x) => {
          x.active = active;
          if (active) x.indeterminate = false;
          x.actions.forEach((y) => {
            y.active = active;
            if (active) y.indeterminate = false;
          });
          x.children.forEach((y) => {
            y.active = active;
            if (active) y.indeterminate = false;
            y.actions.forEach((z) => {
              z.active = active;
            });
          });
        });

        if (active) this.newPermission.indeterminate = false;
      },
      changeMasterPermission(parent) {
        let isActive = parent.active;

        this.newPermission.permissions.forEach((x) => {
          if (x.name == parent.name) {
            if (isActive) x.indeterminate = false;

            x.actions.forEach((y) => {
              y.active = isActive;
              if (isActive) y.indeterminate = false;
            });

            x.children.forEach((y) => {
              y.active = isActive;
              if (isActive) y.indeterminate = false;
              y.actions.forEach((z) => {
                z.active = isActive;
              });
            });
          }
        });

        this.updateIsAllPermissionsActive();
      },
      changeMasterPermissionActions(parent, action) {
        let vm = this;

        function actionConflictAdjustment() {
          if (
            [CRUDType.CREATE, CRUDType.UPDATE, CRUDType.DELETE].includes(
              action.name
            ) &&
            action.active
          ) {
            vm.newPermission.permissions.forEach((x) => {
              if (x.name == parent.name) {
                x.children.forEach((y) => {
                  y.actions.find((z) => z.name == CRUDType.READ).active = true;
                });

                x.actions.forEach((y) => {
                  if (y.name == CRUDType.READ) {
                    y.active = true;
                    y.indeterminate = false;
                  }
                });
              }
            });
          }
        }

        function updateChildrenSelectedActionActive() {
          vm.newPermission.permissions
            .find((x) => x.name == parent.name)
            .children.forEach((x) => {
              x.actions.forEach((y) => {
                if (y.name == action.name) {
                  y.active = action.active;
                }
              });
            });
        }

        function updateChildrenMasterPermissionAndMasterPermissionActive() {
          let isActive = true;

          parent.actions.forEach((x) => {
            if (!x.active) {
              isActive = false;
            }
          });

          vm.newPermission.permissions.forEach((x) => {
            if (x.name == parent.name) {
              x.active = isActive;
              x.children.forEach((y) => {
                y.active = isActive;
              });
            }
          });
        }

        function updateChildrenSelectedActionIndeterminate() {
          if (action.active) {
            vm.newPermission.permissions
              .find((x) => x.name == parent.name)
              .actions.find((x) => x.name == action.name).indeterminate = false;
          }
        }

        function updateChildrenMasterPermissionIndeterminate() {
          let hasActive = false;
          let isAllActive = true;

          parent.children.forEach((x) => {
            x.actions.forEach((y) => {
              if (y.active) {
                hasActive = true;
              } else {
                isAllActive = false;
              }
            });
          });

          vm.newPermission.permissions
            .find((x) => x.name == parent.name)
            .children.forEach((x) => {
              x.indeterminate = isAllActive ? false : hasActive;
            });
        }

        actionConflictAdjustment();
        updateChildrenSelectedActionActive();
        updateChildrenMasterPermissionAndMasterPermissionActive();
        updateChildrenSelectedActionIndeterminate();
        updateChildrenMasterPermissionIndeterminate();
        this.updateMasterPermissionIndeterminate(parent);
        this.updateIsAllPermissionsActive();
      },
      changeChildrenMasterPermission(parent, children) {
        let vm = this;

        function updateSelectedChildrenPermissionsActive() {
          vm.newPermission.permissions
            .find((x) => x.name == parent.name)
            .children.find((x) => x.name == children.name)
            .actions.forEach((x) => {
              x.active = children.active;
            });
        }

        function updateSelectedChildrenPermissionsIndeterminate() {
          if (children.active) {
            vm.newPermission.permissions
              .find((x) => x.name == parent.name)
              .children.find(
                (x) => x.name == children.name
              ).indeterminate = false;
          }
        }

        function updateMasterPermissionActionIndeterminate() {
          let hasActive = false;
          let isAllActive = true;

          parent.children.forEach((x) => {
            if (x.active) {
              hasActive = true;
            } else {
              isAllActive = false;
            }
          });

          vm.newPermission.permissions
            .find((x) => x.name == parent.name)
            .actions.forEach((x) => {
              x.indeterminate = isAllActive ? false : hasActive;
            });
        }

        updateSelectedChildrenPermissionsActive();
        this.updateSelectedMasterPermissionAndActionActive(parent);
        updateSelectedChildrenPermissionsIndeterminate();
        updateMasterPermissionActionIndeterminate();
        this.updateMasterPermissionIndeterminate(parent);
        this.updateIsAllPermissionsActive();
      },
      changeChildrenPermissionActions(parent, children, action) {
        let vm = this;

        function childrenActionConflictAdjustment() {
          let isAllReadActionActive = true;

          if (
            [CRUDType.CREATE, CRUDType.UPDATE, CRUDType.DELETE].includes(
              action.name
            ) &&
            action.active
          ) {
            vm.newPermission.permissions.forEach((x) => {
              if (x.name == parent.name) {
                x.children
                  .find((x) => x.name == children.name)
                  .actions.find((x) => x.name == CRUDType.READ).active = true;

                x.actions.find(
                  (x) => x.name == CRUDType.READ
                ).indeterminate = true;
              }
            });
          }

          parent.children.forEach((x) => {
            x.actions.forEach((y) => {
              if (y.name == CRUDType.READ && !y.active) {
                isAllReadActionActive = false;
              }
            });
          });

          if (isAllReadActionActive) {
            vm.newPermission.permissions
              .find((x) => x.name == parent.name)
              .actions.find(
                (x) => x.name == CRUDType.READ
              ).indeterminate = false;
          }
        }

        function updateSelectedMasterPermissionActionActive() {
          let newCRUD = [];

          vm.crudList.forEach((x) => {
            let isActive = true;

            parent.children.forEach((y) => {
              if (y.actions.find((z) => z.name == x)) {
                if (!y.actions.find((z) => z.name == x).active) {
                  isActive = false;
                }
              }
            });

            if (isActive) {
              newCRUD.push(x);
            }
          });

          vm.newPermission.permissions
            .find((x) => x.name == parent.name)
            .actions.forEach((x) => {
              x.active = newCRUD.includes(x.name);
            });
        }

        function updateSelectedChildrenMasterPermissionActive() {
          let isAllActive = true;

          children.actions.forEach((x) => {
            if (!x.active) {
              isAllActive = false;
            }
          });

          vm.newPermission.permissions
            .find((x) => x.name == parent.name)
            .children.find((y) => y.name == children.name).active = isAllActive;
        }

        function updateSelectedMasterPermissionActionIndeterminate() {
          let hasActive = false;
          let isAllActive = true;

          parent.children.forEach((x) => {
            x.actions.forEach((y) => {
              if (y.name == action.name && y.active) {
                hasActive = true;
              }
            });
          });

          parent.children.forEach((x) => {
            if (x.actions.find((y) => y.name == action.name)) {
              if (!x.actions.find((y) => y.name == action.name).active) {
                isAllActive = false;
              }
            }
          });

          vm.newPermission.permissions
            .find((x) => x.name == parent.name)
            .actions.find((x) => x.name == action.name).indeterminate =
            isAllActive ? false : hasActive;
        }

        function updateSelectedMasterPermissionIndeterminate() {
          let hasActive = false;
          let isAllActionActive = true;

          parent.children
            .find((x) => x.name == children.name)
            .actions.forEach((y) => {
              if (y.active) {
                hasActive = true;
              } else {
                isAllActionActive = false;
              }
            });

          vm.newPermission.permissions
            .find((x) => x.name == parent.name)
            .children.find((x) => x.name == children.name).indeterminate =
            isAllActionActive ? false : hasActive;
        }

        childrenActionConflictAdjustment();
        updateSelectedChildrenMasterPermissionActive();
        this.updateSelectedMasterPermissionAndActionActive(parent);
        updateSelectedMasterPermissionActionActive();
        updateSelectedMasterPermissionActionIndeterminate();
        updateSelectedMasterPermissionIndeterminate();
        this.updateMasterPermissionIndeterminate(parent);
        this.updateIsAllPermissionsActive();
      },
      updateSelectedMasterPermissionAndActionActive(parent) {
        let isAllActive = true;

        parent.children.forEach((x) => {
          if (!x.active) {
            isAllActive = false;
          }
        });

        this.newPermission.permissions.forEach((x) => {
          if (x.name == parent.name) {
            x.active = isAllActive;

            x.actions.forEach((y) => {
              y.active = isAllActive;
            });
          }
        });
      },
      updateIsAllPermissionsActive() {
        let isAllActive = true;
        let hasIndeterminate = false;

        this.newPermission.permissions.forEach((x) => {
          if (!x.active) {
            isAllActive = false;
          }

          if (x.active || x.indeterminate) {
            hasIndeterminate = true;
          }
        });

        this.newPermission.active = isAllActive;
        this.newPermission.indeterminate = isAllActive
          ? false
          : hasIndeterminate;
      },
      updateMasterPermissionIndeterminate(parent) {
        let hasActive = false;
        let isAllActive = true;

        parent.children.forEach((x) => {
          x.actions.forEach((y) => {
            if (y.active) {
              hasActive = true;
            } else {
              isAllActive = false;
            }
          });
        });

        this.newPermission.permissions.find(
          (x) => x.name == parent.name
        ).indeterminate = isAllActive ? false : hasActive;
      },
      submitForm() {
        let validation = this.validationCheck();
        let data = {
          name: this.newRoleName,
          permissions: []
        };

        this.newPermission.permissions.forEach((x) => {
          x.children.forEach((y) => {
            y.actions.forEach((z) => {
              if (z.active) {
                data.permissions.push(z.value);
              }
            });
          });
        });

        if (validation) {
          this.$emit('submit', data);
        }
      },
      deleteRoleAction() {
        this.$emit('delete-role-action');
      },
      resetValidation() {
        this.$nextTick(() => {
          this.$v.$reset();
        });
      },
      validationCheck() {
        this.$v.newRoleName.$touch();

        if (this.$v.newRoleName.$anyError) {
          return false;
        }

        return true;
      },
      validateNewRoleNameState() {
        const { $dirty, $error } = this.$v.newRoleName;
        return $dirty ? !$error : null;
      },
      injectPermission() {
        this.newRoleName = this.existingRoleName;
        this.newPermission = this.existingPermission;
        this.isExpandAllChildrenPermissions = false;
        this.$emit('reset-inject', false);
      }
    },
    validations: {
      newRoleName: {
        required
      }
    }
  };
</script>

<style lang="scss">
  .permissions-wrapper {
    min-width: 600px;
  }
  .children-row {
    background-color: #f3f6f9;
  }
  .expand-icon-span {
    min-height: 2rem;
  }
</style>
