import { CONSTANTS_AVAILABLE_PERMISSIONS } from '@/core/store/constants.module';
import { LOCAL } from '@/core/constants';
import { uiHelper } from '@/core/utils';

const constants = {
  computed: {
    availablePermissions() {
      return this.$store.getters[CONSTANTS_AVAILABLE_PERMISSIONS];
    },
    constantsData() {
      let constants = uiHelper.getLocalStorage(LOCAL.CONSTANTS);

      return constants
        ? JSON.parse(constants)
        : this.$store.state.constants.constants.data;
    }
  },
  methods: {
    getDescription(header, value) {
      let description;
      let newArray = [];

      if (header && value) {
        if (Array.isArray(value)) {
          if (value.length > 0) {
            value.forEach((x) => {
              newArray.push(this.getDescription(header, x));
            });
            description = newArray.join(', ');
          } else {
            description = '-';
          }
        } else {
          description = this.constantsData[header].find(
            (x) => x.value == value
          ).description;
        }
      } else {
        description = '-';
      }

      return description;
    }
  }
};

export default constants;
